<script>
import { mapActions } from 'vuex'
export default {
  data: () => ({
    newPassword: '',
    isHiddePassword: true
  }),
  computed: {
    isCorrectlyPassword () {
      if (this.newPassword.length > 6) {
        return true
      }
      return false
    }
  },
  methods: {
    ...mapActions({
      A_CHANGE_PASSWORD: 'Auth/A_CHANGE_PASSWORD'
    }),
    async changePassword () {
      try {
        if (this.isCorrectlyPassword) {
          await this.A_CHANGE_PASSWORD({ newPassword: this.newPassword, idUser: this.$route.params.id })
          this.$router.push({ name: 'ConfirmationResetPasswordPage' })
        }
      } catch (error) {
        console.log(error)
      }
    },
    viewPassword () {
      this.isHiddePassword = !this.isHiddePassword
    }
  }
}
</script>
<template lang="pug">
  .container
    .header
      img.logo(
        src="@/assets/images/login/logo.svg"
      )
    .form
      .title Elige una contraseña nueva
      .text Crea una contraseña nueva de 6 caracteres como minimo. Una contraseña segura tiene una combinacion de letras, numero y signos de puntuacion.
      .input-password-view
        input.input(
          :type="isHiddePassword ? 'password' : 'text'"
          placeholder="Contraseña nueva"
          v-model="newPassword"
        )
        img.icon-view-password(
          src="@/assets/images/view.png"
          @click="viewPassword"
        )
      .text-correction(
        v-if="!isCorrectlyPassword && newPassword.length > 0"
      ) La contraseña debe tener como minimo 6 caracteres
      button.btn(
        @click="changePassword"
      ) Continuar
</template>
<style lang="scss" scoped>
  $colorText: #006AB6;
  .container {
    background: #D2EFF7;
    height: 100vh;
  }
  .header {
    width: 100%;
    padding: 20px 0;
  }
  .logo {
    height: 40px;
    margin: 0 0 0 100px;
  }
  .form {
    display:flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    width: 400px;
    background: white;
    border-radius: 10px;
    margin: auto;
    padding: 10px;
    .title {
      color: $colorText;
      font-size: 24px;
      margin: 10px 0 20px 0;
      font-weight: 700;
    }
    .text {
      color: $colorText;
      font-size: 14px;
      font-weight: 500;
      padding: 10px 20px;
      line-height: 1.5;
      text-align: center;
      margin: 20px 0;
    }
    .text-correction {
      color: #ef5350;
      font-size: 14px;
      margin: 0 0 30px 0;
    }
    .input-password-view {
      width: 80%;
      position: relative;
      display: flex;
      height: 50px;
      margin: 0 0 20px 0;
      align-items: center;
      justify-content: center;
      .input {
        border-radius: 50px;
        width: 100%;
        height: 100%;
        padding: 15px 25px;
        background: rgb(230,230,230);
      }
      .icon-view-password {
        width: 30px;
        height: 20px;
        cursor: pointer;
        position: absolute;
        left: 100%;
        transform: translateX(-150%);
      }
    }
    .btn {
      border-radius: 50px;
      background: $colorText;
      color: white;
      padding: 10px;
      border: none;
      outline: none;
      cursor: pointer;
      width: 50%;
      margin: 0 0 20px 0;
    }
  }
</style>
